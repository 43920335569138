import React from 'react';
import styled from 'styled-components';
import {Button, Table, Modal} from 'antd';
import GenericForm from 'rev.sdk.js/Generic/Form';
import {useOutlet} from 'reconnect.js';
import qs from 'query-string';
import * as AppActions from '../../AppActions';
import {ModalCustomStyle} from '../../AppContext/customStyledCss';
import AdminSelectUserCustomWidget from '../../Generators/AdminResource/AdminSelectUserCustomWidget';
import AdminSelectCompanyWidget from '../../Generators/AdminResource/AdminSelectCompanyWidget';
import {
  FormSpec,
  fetchUprsByBaseline,
  fetchUserProfiles,
  exportExcelFile,
} from '../../Utils/UserExportUtil';
import {
  getUserStateLabel,
  getUserSubstateLabel,
  getUserSexLabel,
} from '../../Utils/UserUtil';
import ExportHelper from '../../TbaExportHelper';

const pageSize = 100;

const Columns = () => [
  {
    title: '查詢結果',
    key: '#',
    render: (_, record) => {
      return (
        <div
          style={{cursor: 'pointer'}}
          onClick={() => {
            window.open(`/admin/users/?action=detail&id=${record.id}`);
          }}>
          <div>姓名: {record.name}</div>
          <div>創建: {new Date(record.created).toLocaleString('sv')}</div>
          <div>
            更新:{' '}
            {record.admin_updated
              ? new Date(record.admin_updated).toLocaleString('sv')
              : new Date(record.created).toLocaleString('sv')}
          </div>
        </div>
      );
    },
  },
];

function getEnumName(field, value) {
  const idx = FormSpec.schema.properties[field].enum.indexOf(value);
  if (Array.isArray(FormSpec.schema.properties[field].enumNames)) {
    return FormSpec.schema.properties[field].enumNames[idx];
  }
  return value;
}

export default function AdminExcelDownloadPage(props) {
  const {token: _token} = qs.parse(props.location.search);
  const [user] = useOutlet('user');
  const helper = new ExportHelper({});

  const [paging, setPaging] = React.useState({offset: 0, limit: pageSize});
  const [fetchResp, setFetchResp] = React.useState(null);
  const [downloadUrl, setDownloadUrl] = React.useState(null);
  const [queryModalData, setQueryModalData] = React.useState({
    visible: false,
    data: {
      qType: '資料異動',
      startDate: new Date(new Date().getTime() - 8 * 24 * 60 * 60 * 1000)
        .toISOString()
        .split('T')[0],
      days: 8,
      sorting: '-created',
      joinDateStart: '',
      joinDateEnd: '',
      exitDateStart: '',
      exitDateEnd: '',
      is_twba_substate_6_setup: true,
    },
  });
  const queryParams = queryModalData.data;
  const token = _token || (user && !user.tmp && user.token);

  const fetchData = React.useCallback(async () => {
    if (!token) {
      return;
    }

    AppActions.setLoading(true);

    try {
      let fetchResp;

      if (queryParams.qType === '進階搜尋') {
        const hasBaselineSetup =
          queryParams.seniority_baseline_date ||
          queryParams.seniority_start ||
          queryParams.seniority_end ||
          queryParams.age_baseline_date ||
          queryParams.age_start ||
          queryParams.age_end;

        if (hasBaselineSetup) {
          fetchResp = await fetchUprsByBaseline({
            queryParams,
            paging,
            token,
          });
        } else {
          fetchResp = await fetchUserProfiles({
            queryParams,
            paging,
            token,
          });
        }
      } else {
        fetchResp = await fetchUserProfiles({
          queryParams,
          paging,
          token,
        });
      }

      if (fetchResp.results.length > 0) {
        setFetchResp(fetchResp);
      } else {
        setFetchResp(null);
      }
    } catch (ex) {
      console.warn(ex);
    }

    AppActions.setLoading(false);
  }, [token, paging, queryParams]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Wrapper>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <h1 className="title">會員查詢</h1>
      </div>
      <div className="content">
        <section
          className="query"
          onClick={() => {
            setQueryModalData({data: queryParams, visible: true});
          }}>
          <h3>查詢參數</h3>
          <div>方式： {queryParams.qType}</div>

          {queryParams.qType === '資料異動' && (
            <div>
              <div>排序： {getEnumName('sorting', queryParams.sorting)}</div>
              <div>
                資料異動區間： 從 {queryParams.startDate} 起 {queryParams.days}
                日
              </div>
              <div>
                特殊設定：
                {queryParams.is_twba_substate_6_setup
                  ? '「外國法事務律師」匯出顯示「一般會員」'
                  : '無'}
              </div>
            </div>
          )}

          {queryParams.qType === '入會區間' && (
            <div>
              <div>排序： {getEnumName('sorting', queryParams.sorting)}</div>
              <div>
                入會時間（起）- (迄)： {queryParams.joinDateStart} -
                {queryParams.joinDateEnd}
              </div>
              <div>
                特殊設定：
                {queryParams.is_twba_substate_6_setup
                  ? '「外國法事務律師」匯出顯示「一般會員」'
                  : '無'}
              </div>
            </div>
          )}

          {queryParams.qType === '退會區間' && (
            <div>
              <div>排序： {getEnumName('sorting', queryParams.sorting)}</div>
              <div>
                退會時間（起）- （訖）： {queryParams.exitDateStart} -
                {queryParams.exitDateEnd}
              </div>
              <div>
                特殊設定：
                {queryParams.is_twba_substate_6_setup
                  ? '「外國法事務律師」匯出顯示「一般會員」'
                  : '無'}
              </div>
            </div>
          )}

          {queryParams.qType === '擇定區間' && (
            <div>
              <div>排序： {getEnumName('sorting', queryParams.sorting)}</div>
              <div>
                擇定時間（起）- （訖）： {queryParams.transformDateStart} -
                {queryParams.transformDateEnd}
              </div>
              <div>
                特殊設定：
                {queryParams.is_twba_substate_6_setup
                  ? '「外國法事務律師」匯出顯示「一般會員」'
                  : '無'}
              </div>
            </div>
          )}

          {queryParams.qType === '進階搜尋' && (
            <div>
              <div>排序： {getEnumName('sorting', queryParams.sorting)}</div>
              <div>
                會員：
                {helper.getUser(queryParams.owner).name}
              </div>
              <div>
                狀態：
                {queryParams.states?.map(
                  (s, idx) =>
                    `${getUserStateLabel(s)}${
                      idx + 1 !== queryParams.states.length ? '、' : ''
                    }`,
                )}
              </div>
              <div>
                次狀態：
                {queryParams.substates?.map(
                  (s, idx) =>
                    `${getUserSubstateLabel(s)}${
                      idx + 1 !== queryParams.substates.length ? '、' : ''
                    }`,
                )}
              </div>
              <div>性別：{getUserSexLabel(queryParams.sex)}</div>
              <div>
                現在事務所：
                {helper.getCompany(queryParams.company_current).CONAME}
              </div>
              <div>學校：{queryParams.school}</div>
              <div>專長：{queryParams.expertise}</div>
              <div>
                年資基準日：{queryParams.seniority_baseline_date} /
                年資區間（起）～ (迄)：{queryParams.seniority_start} ～
                {queryParams.seniority_end} 年
              </div>
              <div>
                生日區間（起）～ (迄)：{queryParams.birthday_date_start} ～
                {queryParams.birthday_date_end}
              </div>
              <div>
                年齡基準日：{queryParams.age_baseline_date} /
                年齡區間（起）～（迄）：{queryParams.age_start} ～
                {queryParams.age_end} 歲
              </div>
              <div>
                許可證有效期限（起）～（迄）：
                {queryParams.expiry_date_start} ～ {queryParams.expiry_date_end}
              </div>
              <div>
                入會區間（起）～（迄）：{queryParams.joinDateStart} ~{' '}
                {queryParams.joinDateEnd}
              </div>
              <div>
                退會區間（起）～（迄）：{queryParams.exitDateStart} ~{' '}
                {queryParams.exitDateEnd}
              </div>
              <div>
                擇定區間（起）～（迄）：{queryParams.transformDateStart} ~{' '}
                {queryParams.transformDateEnd}
              </div>
            </div>
          )}
        </section>

        <section className="actions">
          <Button
            onClick={async () => {
              AppActions.setLoading(true);
              setDownloadUrl(null);
              await exportExcelFile({
                queryParams,
                token,
                setDownloadUrl,
              });
              AppActions.setLoading(false);
            }}>
            匯出Excel
          </Button>
          {downloadUrl && (
            <Button
              type="link"
              download={'users.xlsx'}
              href={downloadUrl}
              target="_blank">
              下載
            </Button>
          )}
        </section>

        <Table
          columns={Columns()}
          dataSource={fetchResp?.results || []}
          rowKey={'id'}
          pagination={{
            size: 'small',
            total: fetchResp?.total || 0,
            showSizeChanger: false,
            showTotal: (total) => `共 ${total} 筆, 每頁 ${pageSize} 筆`,
            current: paging.offset / pageSize + 1,
            pageSize: pageSize,
            position: ['topLeft'],
            onChange: (page, pageSize) => {
              if (pageSize) {
                setPaging({
                  offset: (page - 1) * pageSize,
                  limit: pageSize,
                });
              }
            },
          }}
        />
      </div>

      <AdminUserQueryModal
        data={queryModalData}
        setData={setQueryModalData}
        setPaging={setPaging}
        fetchData={fetchData}
      />
    </Wrapper>
  );
}

function AdminUserQueryModal(props) {
  const {
    data: {data, visible},
    setData,
  } = props;
  return (
    <Modal
      title={null}
      footer={null}
      bodyStyle={{padding: 0}}
      width={600}
      visible={visible}
      onOk={() => {
        setData({
          data,
          visible: false,
        });
      }}
      onCancel={() => {
        setData({
          data,
          visible: false,
        });
      }}
      destroyOnClose={true}>
      {visible && <QueryModalContent {...props} />}
    </Modal>
  );
}

function QueryModalContent(props) {
  const {
    data: {data: prevData},
    setData,
    setPaging,
  } = props;

  const rjsfProps = {
    widgets: {
      'admin-select-user-custom-widget': AdminSelectUserCustomWidget,
      'admin-select-company-widget': AdminSelectCompanyWidget,
    },
  };

  return (
    <ModalWrapper>
      <h1>查詢參數</h1>

      <GenericForm
        {...FormSpec}
        rjsfProps={rjsfProps}
        instance={{...prevData}}
        onSubmit={async (formData, extValues) => {
          setPaging({offset: 0, limit: pageSize});
          setData({visible: false, data: {...prevData, ...formData}});
        }}
      />
    </ModalWrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;
  background-color: #f8f9fa;
  min-height: 100vh;

  & h1.title {
    font-size: 32px;
  }

  & > .content {
    width: min(720px, 100% - 20px);
    margin-inline: auto;
    background-color: white;
    border-radius: 12px;
    border: 1px solid #ddd;
    padding: 20px 10px;

    h1 {
      font-size: 18px;
      margin-bottom: 10px;
    }

    section.query {
      border: 1px dashed var(--adminBorderColor);
      border-radius: 4px;
      padding: 12px;
      cursor: pointer;
      margin-bottom: 10px;

      :hover {
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      }
    }

    section.actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 10px;
    }
  }
`;

const ModalWrapper = styled(ModalCustomStyle)`
  padding: 20px;
`;
